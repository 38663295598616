// title: Lucky Paper Newsletter
// frameCardName: Celestial Messenger
// frameCardSet: M20

import * as styles from './newsletter.module.scss'

import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'
import * as Typography from 'components/typography'

import NewsletterSignUpForm from 'components/newsletter/NewsletterSignUpForm'

interface Props extends PageProps<Queries.NewsletterPageQuery> {
  pageContext: any
}

const NewsletterPage: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <Layout {...props.pageContext}>
      <article className={styles.container}>
        <h1>
          <Typography.PrimaryHeading>
            Lucky Paper Newsletter
          </Typography.PrimaryHeading>
        </h1>
        <div className={styles.newsletterSignUpForm}>
          <NewsletterSignUpForm />
        </div>
        <Typography.Paragraph>
          Our infrequent, text-only newsletter is a friendly way to stay
          up-to-date with what we&rsquo;re doing at Lucky Paper. Be the first to
          read new articles, never miss a opportunity to participate in our
          community surveys, and enjoy Lucky Paper founder Andy Mangold&rsquo;s
          musings about Cube and Magic: the Gathering in general. We will never
          spam you or sell your information.
        </Typography.Paragraph>
        <Typography.SecondaryHeading>Archive</Typography.SecondaryHeading>
        {data.campaigns.nodes.map((campaign) => (
          <a
            href={campaign.webVersionURL ?? ''}
            target="_blank"
            rel="noopener noreferrer"
            key={campaign.id}
            className={styles.archivedCampaign}
          >
            <span className={styles.archivedCampaignSubjectLine}>
              {campaign.subject}
            </span>
            <span className={styles.archivedCampaignSentDate}>
              {campaign.sentDate}
            </span>
          </a>
        ))}
        <Typography.Divider />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query NewsletterPage {
    campaigns: allCampaign(sort: { sentDate: DESC }) {
      nodes {
        id
        subject
        sentDate(formatString: "MMMM Do, YYYY")
        webVersionURL
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Lucky Paper Newsletter',
        description:
          'Sign up for the Lucky Paper newsletter for infrequent updates about new articles, set prospective surveys, and new tools to learn about and explore sandbox Magic: the Gathering environments.',
      }}
    />
  )
}

export default NewsletterPage
